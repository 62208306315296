import { useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { ADMIN_BID_APPLY_CURRENCY_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import {
  ApplyBidFeeData,
  ApplyBidFormData,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import { findExchangeRate } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

function CurrencySelect({
  setValue,
  control,
  exchangeRateList,
  feeDataType,
}: {
  setValue: UseFormSetValue<ApplyBidFormData>;
  control: Control<ApplyBidFormData>;
  exchangeRateList: ExchangeRate[];
  feeDataType:
    | "freightFeeData"
    | "domesticFeeData"
    | "localFeeData"
    | "otherFeeData"
    | "inlandFeeData"
    | "taxFeeData";
}) {
  const feeFormList = useWatch({
    control,
    name: feeDataType,
  });

  const [currency, setCurrency] = useState<Currency>("KRW");

  const getItemPrice = (currency: Currency, applyFormData: ApplyBidFeeData) => {
    if (!applyFormData.amount) return 0;

    if (currency === "KRW") {
      return Math.ceil(applyFormData.unitPrice * applyFormData.amount);
    }

    return (applyFormData.itemPrice = Math.ceil(
      findExchangeRate(exchangeRateList, currency) *
        applyFormData.unitPrice *
        applyFormData.amount
    ));
  };

  const handleCurrencyChange = () => {
    const newFeeFormList = feeFormList.map((feeFormItem) => {
      return {
        ...feeFormItem,
        currency: currency,
        itemPrice: getItemPrice(currency, feeFormItem),
      };
    });

    setValue(feeDataType, [...newFeeFormList], {});
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl variant="outlined">
        <InputLabel id="select-filter-label">환율 일괄선택</InputLabel>

        <Select
          labelId="currency-select-filter-label"
          id="currency-select-filter"
          value={currency}
          label={"환율 일괄선택"}
          onChange={(e) => {
            setCurrency(e.target.value as Currency);
          }}
          size="small"
          sx={{ width: "150px" }}
        >
          {ADMIN_BID_APPLY_CURRENCY_OPTION_LIST.filter((option) => {
            return option.value !== "SGD";
          }).map((option) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Button variant="contained" onClick={handleCurrencyChange}>
        적용
      </Button>
    </Box>
  );
}

export default CurrencySelect;
