import { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { TeamAuthorityDetail } from "@sellernote/_shared/src/types/common/team";
import { TeamAndUserDetail } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { TeamMember } from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { changeTeamRoleToKr } from "@sellernote/_shared/src/utils/forwarding/admin/adminTeam";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../LegacyTable";
import MemberDetailModal from "./MemberDetailModal";

type CellId = keyof TeamMember;

function MemberInfo({ detailData }: { detailData: TeamAndUserDetail }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [showsMemberDetailModal, setShowsMemberDetailModal] = useState(false);
  const [permissionDetail, setPermissionDetail] = useState<
    TeamAuthorityDetail | undefined
  >();

  const handleMemberDetailModalOpen = (
    permissionDetail: TeamAuthorityDetail
  ) => {
    return () => {
      setPermissionDetail(permissionDetail);
      setShowsMemberDetailModal(true);
    };
  };

  const handleMemberDetailModalClose = () => {
    setShowsMemberDetailModal(false);
  };

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "userId",
        disablePadding: false,
        label: "NO",
        verticalAlign: "top",
      },

      {
        id: "permission",
        disablePadding: false,
        label: "권한",
      },
      {
        id: "country",
        disablePadding: false,
        label: "국가",
        verticalAlign: "top",
      },
      {
        id: "name",
        disablePadding: false,
        label: "담당자 이름",
        verticalAlign: "top",
      },
      {
        id: "phone",
        disablePadding: false,
        label: "연락처",
        verticalAlign: "top",
      },
      {
        id: "email",
        disablePadding: false,
        label: "이메일",
        verticalAlign: "top",
      },
      {
        id: "searchCount",
        disablePadding: false,
        label: "검색건수",
        verticalAlign: "top",
      },
      {
        id: "bidCount",
        disablePadding: false,
        label: "의뢰건수",
        verticalAlign: "top",
      },
      {
        id: "inProgressBidCount",
        disablePadding: false,
        label: "진행건수",
        verticalAlign: "top",
      },
      {
        id: "remittanceAgree",
        disablePadding: false,
        label: "제3자 동의",
        verticalAlign: "top",
      },
      {
        id: "joinedAt",
        disablePadding: false,
        label: "초대수락일자",
        verticalAlign: "top",
      },
    ];
    return headCells;
  }, []);

  const rows = useMemo(() => {
    if (!detailData.members) return [];

    return (
      detailData.members
        // API 요청이 없어서 자체 페이지네이션을 한다.
        .slice(currentPage * perPage, currentPage * perPage + perPage)
        .map((v) => {
          const row: LegacyTableBodyRow<CellId> = {
            userId: v.userId,
            permission: (
              <Box>
                <Typography variant="body2" component={"div"}>
                  {changeTeamRoleToKr(v.permission.teamRole)}
                </Typography>

                <Button
                  onClick={handleMemberDetailModalOpen(v.permission.detail)}
                >
                  {"[상세보기]"}
                </Button>
              </Box>
            ),
            country: getCountryCodeKR(v.country),
            name: v.name,
            phone: v.phone,
            email: v.email,
            searchCount: v.searchCount,
            bidCount: v.bidCount,
            inProgressBidCount: v.inProgressBidCount,
            remittanceAgree: v.remittanceAgree ? "O" : "X",
            joinedAt: toFormattedDate(v.joinedAt),
          };

          return row;
        })
    );
  }, [currentPage, perPage, detailData.members]);

  return (
    <>
      <LegacyTable
        title={"멤버 정보"}
        headCells={HeadCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
          totalCount: detailData?.members?.length || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />

      {showsMemberDetailModal && (
        <MemberDetailModal
          handleMemberDetailModalClose={handleMemberDetailModalClose}
          showsMemberDetailModal={showsMemberDetailModal}
          permissionDetail={permissionDetail}
        />
      )}
    </>
  );
}

export default MemberInfo;
