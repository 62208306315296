import { useMemo, useState } from "react";

import ADMIN_FORWARDERFARE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FORWARDERFARE_QUERY";
import { Port } from "@sellernote/_shared/src/types/common/common";
import { adminForwarderfareTableProps } from "@sellernote/_shared/src/types/forwarding/adminForwarderfare";
import { getPortName } from "@sellernote/_shared/src/utils/forwarding/admin/port";

import {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../components/LegacyTable";

export function useAdminFareTable({
  portList,
  freightType,
}: {
  portList: Port[] | undefined;
  freightType: string;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const {
    data: forwarderfareList,
    isFetched,
    isError,
  } = ADMIN_FORWARDERFARE_QUERY.useGetAdminForwarderfare({
    freightType: freightType,
    page: currentPage,
    perPage: perPage,
  });

  type CellId = keyof adminForwarderfareTableProps;

  const headCells: LegacyTableHeadCell<CellId>[] | undefined = useMemo(() => {
    if (freightType === "AIR")
      return [
        {
          id: "id",
          disablePadding: false,
          label: "번호",
          width: 70,
        },
        {
          id: "startPortId",
          disablePadding: false,
          label: "출발지",
          width: 110,
        },
        {
          id: "endPortId",
          disablePadding: false,
          label: "도착지",
          width: 110,
        },
        {
          id: "isConfirm",
          disablePadding: false,
          label: "확인여부",
          width: 90,
        },
        {
          id: "cwMin",
          disablePadding: false,
          label: "항공운임(MIN)",
          width: 90,
        },
        {
          id: "cw45",
          disablePadding: false,
          label: "45-100KG(C.W)",
          width: 120,
        },
        {
          id: "cw100",
          disablePadding: false,
          label: "100-300KG(C.W)",
          width: 120,
        },
        {
          id: "cw300",
          disablePadding: false,
          label: "300-500KG(C.W)",
          width: 120,
        },
        {
          id: "cw500",
          disablePadding: false,
          label: "500-1T(C.W)",
          width: 120,
        },

        {
          id: "cw1000",
          disablePadding: false,
          label: "1T-3T(C.W)",
          width: 120,
        },
        {
          id: "thc",
          disablePadding: false,
          label: "THC(C.W)",
          width: 100,
        },
        {
          id: "docFee",
          disablePadding: false,
          label: "DOC FEE(B/L)",
          width: 100,
        },
        {
          id: "hc",
          disablePadding: false,
          label: "H/C(B/L)",
          width: 100,
        },
        {
          id: "liner",
          disablePadding: false,
          label: "운송사명",
          width: 120,
        },

        {
          id: "leadtime",
          disablePadding: false,
          label: "소요일",
          width: 70,
        },
        {
          id: "schedule",
          disablePadding: false,
          label: "스케줄",
          width: 70,
        },
        {
          id: "transitType",
          disablePadding: false,
          label: "직항여부",
          width: 90,
        },
        {
          id: "expiredAt",
          disablePadding: false,
          label: "유효기간",
          width: 100,
        },
      ];
    if (freightType === "FCL")
      return [
        {
          id: "id",
          disablePadding: false,
          label: "번호",
          width: 70,
        },
        {
          id: "startPortId",
          disablePadding: false,
          label: "출발지",
          width: 110,
        },
        {
          id: "endPortId",
          disablePadding: false,
          label: "도착지",
          width: 110,
        },
        {
          id: "containerType",
          disablePadding: false,
          label: "컨테이너유형",
          width: 90,
        },
        {
          id: "oceanFreight",
          disablePadding: false,
          label: "해상운임(CNTR)",
          width: 90,
        },
        {
          id: "bafCaf",
          disablePadding: false,
          label: "BAF&CAF/CRS(CNTR)",
          width: 120,
        },
        {
          id: "lss",
          disablePadding: false,
          label: "LSS(CNTR)",
          width: 120,
        },
        {
          id: "totalAdditionalFee",
          disablePadding: false,
          label: "CRS(긴급비용할증료)",
          width: 120,
        },
        {
          id: "thc",
          disablePadding: false,
          label: "THC(CNTR)",
          width: 100,
        },
        {
          id: "wfg",
          disablePadding: false,
          label: "WFG(CNTR)",
          width: 120,
        },

        {
          id: "docFee",
          disablePadding: false,
          label: "DOC FEE(B/L)",
          width: 120,
        },
        {
          id: "hc",
          disablePadding: false,
          label: "H/C(B/L)",
          width: 100,
        },

        {
          id: "ccc",
          disablePadding: false,
          label: "C.C.C(R.TON)",
          width: 100,
        },
        {
          id: "doFee",
          disablePadding: false,
          label: "D/O FEE(B/L)",
          width: 120,
        },
        {
          id: "etcCNTR",
          disablePadding: false,
          label: "기타비용(R.TON)",
          width: 120,
        },
        {
          id: "etcBL",
          disablePadding: false,
          label: "기타비용(B/L)",
          width: 120,
        },
        {
          id: "liner",
          disablePadding: false,
          label: "운송사명",
          width: 200,
        },

        {
          id: "leadtime",
          disablePadding: false,
          label: "소요일",
          width: 70,
        },
        {
          id: "schedule",
          disablePadding: false,
          label: "스케줄",
          width: 70,
        },
        {
          id: "transitType",
          disablePadding: false,
          label: "직항여부",
          width: 90,
        },
        {
          id: "expiredAt",
          disablePadding: false,
          label: "유효기간",
          width: 100,
        },
      ];
    else
      return [
        {
          id: "id",
          disablePadding: false,
          label: "번호",
          width: 70,
        },
        {
          id: "startPortId",
          disablePadding: false,
          label: "출발지",
          width: 110,
        },
        {
          id: "endPortId",
          disablePadding: false,
          label: "도착지",
          width: 110,
        },
        {
          id: "oceanFreightMin",
          disablePadding: false,
          label: "해상운임(MIN)",
          width: 110,
        },
        {
          id: "oceanFreight",
          disablePadding: false,
          label: "해상운임(R.TON)",
          width: 90,
        },
        {
          id: "bafCaf",
          disablePadding: false,
          label: "BAF/CAF/CRS(R.TON)",
          width: 120,
        },
        {
          id: "lss",
          disablePadding: false,
          label: "LSS(R.TON)",
          width: 120,
        },
        {
          id: "totalAdditionalFee",
          disablePadding: false,
          label: "CRS(긴급비용할증료)",
          width: 120,
        },
        {
          id: "thc",
          disablePadding: false,
          label: "THC(R.TON)",
          width: 120,
        },
        {
          id: "wfg",
          disablePadding: false,
          label: "WFG(R.TON)",
          width: 120,
        },

        {
          id: "docFee",
          disablePadding: false,
          label: "DOC FEE(B/L)",
          width: 120,
        },
        {
          id: "hc",
          disablePadding: false,
          label: "H/C(B/L)",
          width: 100,
        },

        {
          id: "ccc",
          disablePadding: false,
          label: "C.C.C(R.TON)",
          width: 100,
        },
        {
          id: "doFee",
          disablePadding: false,
          label: "D/O FEE(B/L)",
          width: 120,
        },

        {
          id: "drayageCharge",
          disablePadding: false,
          label: "Drayage Charge(R.TON)",
          width: 200,
        },
        {
          id: "cfsCharge",
          disablePadding: false,
          label: "cfsCharge(R.TON)",
          width: 200,
        },
        {
          id: "etcCNTR",
          disablePadding: false,
          label: "기타비용(R.TON)",
          width: 140,
        },
        {
          id: "etcBL",
          disablePadding: false,
          label: "기타비용(B/L)",
          width: 120,
        },
        {
          id: "liner",
          disablePadding: false,
          label: "운송사명",
          width: 120,
        },
        {
          id: "leadtime",
          disablePadding: false,
          label: "소요일",
          width: 70,
        },
        {
          id: "schedule",
          disablePadding: false,
          label: "스케줄",
          width: 70,
        },
        {
          id: "transitType",
          disablePadding: false,
          label: "직항여부",
          width: 90,
        },
        {
          id: "expiredAt",
          disablePadding: false,
          label: "유효기간",
          width: 100,
        },
      ];
  }, [freightType]);

  const Rows = useMemo(() => {
    if (!forwarderfareList?.list) return [];
    if (freightType === "AIR")
      return forwarderfareList?.list.map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          id: v.id,
          startPortId: getPortName(portList, v.startPortId) || v.startCountry,
          endPortId: getPortName(portList, v.endPortId) || v.endCountry,
          isConfirm: v.isConfirm ? "예" : "아니오",
          cwMin: `$${v.cwMin}`,
          cw45: `$${v.cw45}`,
          cw100: `$${v.cw100}`,
          cw300: `$${v.cw300}`,
          cw500: `$${v.cw500}`,
          cw1000: `$${v.cw1000}`,
          thc: `₩${v.thc}`,
          docFee: `₩${v.docFee}`,
          hc: `₩${v.hc}`,
          liner: v.liner,
          leadtime: v.leadtime,
          schedule: v.schedule,
          transitType: v.transitType,
          expiredAt: v.expiredAt,
        };

        return row;
      });
    if (freightType === "FCL")
      return forwarderfareList?.list.map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          id: v.id,
          startPortId: getPortName(portList, v.startPortId) || v.startCountry,
          endPortId: getPortName(portList, v.endPortId) || v.endCountry,
          containerType: v.containerType,
          oceanFreight: v.oceanFreight,
          bafCaf: v.bafCaf,
          lss: v.lss,
          totalAdditionalFee: v.totalAdditionalFee,
          thc: `₩${v.thc}`,
          wfg: v.wfg,
          docFee: `₩${v.docFee}`,
          hc: `₩${v.hc}`,
          ccc: v.ccc,
          doFee: v.doFee,
          etcCNTR: v.etcCNTR,
          etcBL: v.etcBL,
          liner: v.liner,
          leadtime: v.leadtime,
          schedule: v.schedule,
          transitType: v.transitType,
          expiredAt: v.expiredAt,
        };

        return row;
      });
    else
      return forwarderfareList?.list.map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          id: v.id,
          startPortId: getPortName(portList, v.startPortId) || v.startCountry,
          endPortId: getPortName(portList, v.endPortId) || v.endCountry,
          isConfirm: v.isConfirm ? "예" : "아니오",
          oceanFreightMin: v.oceanFreightMin,
          oceanFreight: v.oceanFreight,
          bafCaf: v.bafCaf,
          lss: v.lss,
          totalAdditionalFee: v.totalAdditionalFee,
          thc: `₩${v.thc}`,
          wfg: v.wfg,
          docFee: `₩${v.docFee}`,
          hc: `₩${v.hc}`,
          ccc: v.ccc,
          doFee: v.doFee,
          drayageCharge: v.drayageCharge,
          cfsCharge: v.cfsCharge,
          etcCNTR: v.etcCNTR,
          liner: v.liner,
          leadtime: v.leadtime,
          schedule: v.schedule,
          transitType: v.transitType,
          expiredAt: v.expiredAt,
        };

        return row;
      });
  }, [forwarderfareList?.list, portList, freightType]);

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: forwarderfareList?.total || 0,
    perPage,
    setPerPage,
    currentPage: forwarderfareList?.total === undefined ? 0 : currentPage,
    setCurrentPage,
  };

  return {
    headCells,
    Rows,
    isFetched,
    isError,
    pagination,
  };
}
