import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fab, Fade } from "@mui/material";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "../../constants";

function BackTop() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    setIsVisible(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fade in={isVisible}>
      <Fab
        onClick={handleClick}
        color="default"
        size="small"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.backTop,
          backgroundColor: "#9e9e9e",
          color: "#fff",
          opacity: 0.7,
          "&:hover": {
            opacity: 1.0,
            backgroundColor: "#757575",
          },
        }}
        aria-label="scroll back to top"
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Fade>
  );
}

export default BackTop;
