import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { UseFormWatch } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";

import ADMIN_TEMPLATE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_TEMPLATE_QUERY";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TemplateCategory } from "@sellernote/_shared/src/types/forwarding/adminTemplate";
import {
  getAdminBidTemplateSavePayload,
  getAdminWithdrawalTemplateSavePayload,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminTemplate";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

import useCheckTemplateData from "../../../hooks/useCheckTemplateData";
import useSnackbar from "../../../hooks/useSnackbar";

import OverwriteTemplateModal from "./OverwriteTemplateModal";

function SaveTemplateModal({
  bidId,
  templateCategory,
  showsSaveTemplateModal,
  setShowsSaveTemplateModal,
  watch,
  templateType,
  isDifferentName,
  bidAccountPayableId,
}: {
  bidId: number;
  showsSaveTemplateModal: boolean;
  setShowsSaveTemplateModal: Dispatch<SetStateAction<boolean>>;
  watch: UseFormWatch<ApplyBidFormData>;
  templateCategory: TemplateCategory;
  templateType: "bidApply" | "withdrawal";
  isDifferentName: boolean;
  bidAccountPayableId?: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [templateName, setTemplateName] = useState("");
  const [showsOverwriteTemplateModal, setShowsOverwriteTemplateModal] =
    useState(false);

  const { getSaveStatus } = useCheckTemplateData();

  const { mutate: saveAdminBidTemplate, isLoading: saveLoading } =
    ADMIN_TEMPLATE_QUERY.useSaveAdminBidTemplate();

  const renamedTemplateSavePayload = useMemo(() => {
    if (templateType === "withdrawal") {
      const adminBidTemplateSavePayload = getAdminWithdrawalTemplateSavePayload(
        {
          withdrawalData: watch("withdrawalData"),
          bidId,
          saveStatus: isDifferentName ? "new" : getSaveStatus(templateCategory),
          category: templateCategory,
          templateName,
          bidAccountPayableId,
        }
      );

      return { ...adminBidTemplateSavePayload, templateName: templateName };
    }
    const adminBidTemplateSavePayload = getAdminBidTemplateSavePayload({
      freightFare: watch("freightFeeData"),
      domesticFare: watch("domesticFeeData"),
      localFare: watch("localFeeData"),
      otherFare: watch("otherFeeData"),
      inlandFare: watch("inlandFeeData"),
      taxFare: watch("taxFeeData"),
      liner: watch("liner"),
      isTransit: watch("isTransit"),
      leadtime: watch("leadtime"),
      freightPaymentType: watch("freightPaymentType"),
      expiredAt: watch("expiredAt"),
      comment: watch("comment"),
      bidId,
      saveStatus: isDifferentName ? "new" : getSaveStatus(templateCategory),
      category: templateCategory,
      templateName,
    });
    return { ...adminBidTemplateSavePayload, templateName: templateName };
  }, [
    bidAccountPayableId,
    bidId,
    getSaveStatus,
    isDifferentName,
    templateCategory,
    templateName,
    templateType,
    watch,
  ]);

  const handleTemplateSave = useCallback(() => {
    saveAdminBidTemplate(renamedTemplateSavePayload, {
      onSuccess: () => {
        handleSnackbarOpen("템플릿이 저장되었습니다.");
        setShowsSaveTemplateModal(false);
      },

      onError: ({ response }) => {
        if (response?.data?.errorCode === "E368") {
          return setShowsOverwriteTemplateModal(true);
        }
        handleSnackbarOpen("템플릿 저장에 실패했습니다.", "error");
      },
    });
  }, [
    handleSnackbarOpen,
    renamedTemplateSavePayload,
    saveAdminBidTemplate,
    setShowsSaveTemplateModal,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsSaveTemplateModal(false);
  }, [setShowsSaveTemplateModal]);

  const ModalBody = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            템플릿 명을 입력해 주세요
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={templateName}
            placeholder="템플릿 명을 입력해주세요"
            fullWidth
            size="small"
            multiline={true}
            inputProps={{ maxLength: 20 }}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </Grid>

        <Grid item container xs={12} spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            >
              취소
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={handleTemplateSave}
              disabled={saveLoading}
            >
              저장
            </Button>
          </Grid>
        </Grid>

        {showsOverwriteTemplateModal && (
          <OverwriteTemplateModal
            showsOverwriteTemplateModal={showsOverwriteTemplateModal}
            setShowsOverwriteTemplateModal={setShowsOverwriteTemplateModal}
            renamedTemplateSavePayload={renamedTemplateSavePayload}
            templateName={templateName}
          />
        )}
      </Grid>
    );
  }, [
    handleModalClose,
    handleTemplateSave,
    renamedTemplateSavePayload,
    saveLoading,
    showsOverwriteTemplateModal,
    templateName,
  ]);

  return (
    <Modal
      isOpened={showsSaveTemplateModal}
      handleClose={handleModalClose}
      modalBody={ModalBody}
    />
  );
}

export default SaveTemplateModal;
