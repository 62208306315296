import { Box, Typography } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import {
  TeamBid,
  TeamSearchLog,
} from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { getPortNameForDetailBidList } from "@sellernote/_shared/src/utils/forwarding/admin/port";

function ShippingInfo({
  infoData,
  portList,
}: {
  infoData: TeamBid | TeamSearchLog;
  portList: Port[] | undefined;
}) {
  return (
    <Box>
      <Typography variant="body2" component="div">
        {infoData.incoterms}
      </Typography>

      <Typography variant="body2" component="div">
        {getPortNameForDetailBidList(infoData, "startPortId", portList)}

        {"->"}

        {getPortNameForDetailBidList(infoData, "endPortId", portList)}
      </Typography>
    </Box>
  );
}

export default ShippingInfo;
