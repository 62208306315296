import { useCallback, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Button, Checkbox, Grid, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { GET_ADMIN_BID_APPLY_ITEMS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { ADMIN_BID_APPLY_CURRENCY_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import {
  ApplyBidFeeData,
  ApplyBidFormData,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { getCustomRound } from "@sellernote/_shared/src/utils/common/number";
import {
  ITEM_UNIT_MEASUREMENT_OPTION_LIST,
  ITEM_UNIT_MEASUREMENT_OPTION_LIST_FOR_LCL_AND_AIR,
} from "@sellernote/_shared/src/utils/common/options";
import { getAmountByItemUnitMeasurement } from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";
import { findExchangeRate } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import {
  checkIsSamePackageInProductList,
  checkOnlyForLCLAndAirPackage,
  checkPriceDifference,
  getQuantityForSameLCLAndAIRPackageItem,
} from "./utils";

import NumericFormatWithReactHookForm from "../../NumericFormatWithReactHookForm";

function ApplyForm({
  itemListForAutoCompleteOptions,
  applyFormData,
  control,
  formIndex,
  isFreightApplyItem,
  applyShipmentFormFeeDataType,
  exchangeRateList,
  setValue,
  bidDetail,
  applyItems,
  showsPreviousItemPrice,
  directAirRton,
}: {
  itemListForAutoCompleteOptions: string[];
  applyFormData: ApplyBidFeeData;
  control: Control<ApplyBidFormData, Record<string, unknown>>;
  formIndex: number;
  isFreightApplyItem: boolean;
  applyShipmentFormFeeDataType:
    | "freightFeeData"
    | "domesticFeeData"
    | "localFeeData"
    | "otherFeeData"
    | "inlandFeeData"
    | "taxFeeData";
  exchangeRateList: ExchangeRate[];
  setValue: UseFormSetValue<ApplyBidFormData>;
  applyItems?: GET_ADMIN_BID_APPLY_ITEMS_RES;
  bidDetail?: TrelloBidDetail;
  showsPreviousItemPrice?: boolean;
  directAirRton?: number;
}) {
  const feeFormList = useWatch({ control, name: applyShipmentFormFeeDataType });

  /** 운영자가 직접 수량을 입력했는지 확인(QA13459) */
  const [isAmountTyped, setIsAmountTyped] = useState(false);

  const isLCLOrAIRType =
    bidDetail?.freightType === "LCL" || bidDetail?.freightType === "AIR";

  const getItemPrice = useCallback(
    (currency: Currency, applyFormData: ApplyBidFeeData) => {
      if (!applyFormData.amount) return 0;

      if (currency === "KRW") {
        return getCustomRound(
          applyFormData.unitPrice * applyFormData.amount,
          0
        );
      }

      // 부동소숫점으로 인한 문제가 있어 getCustomRound를 사용
      return getCustomRound(
        applyFormData.unitPrice *
          applyFormData.amount *
          findExchangeRate(exchangeRateList, currency),
        0
      );
    },
    [exchangeRateList]
  );

  const getNewFormData = useCallback(() => {
    const newApplyFormData = { ...applyFormData };

    newApplyFormData.itemPrice = getItemPrice(
      newApplyFormData.currency,
      newApplyFormData
    );

    return newApplyFormData;
  }, [applyFormData, getItemPrice]);

  const handleRemoveForm = () => {
    const newFeeFormList = feeFormList.filter(
      (_, index) => index !== formIndex
    );

    setValue(applyShipmentFormFeeDataType, newFeeFormList);
  };

  const handleItemUnitMeasurementChange = useCallback(() => {
    const newApplyFormData = { ...applyFormData };

    const { itemUnitMeasurement } = newApplyFormData;

    const isLCLAndAirPackage =
      checkOnlyForLCLAndAirPackage(itemUnitMeasurement);

    if (bidDetail) {
      // 직접입력한 AIR Rton이 있는 경우에는 직접 입력한 값이 수량이 된다.
      const amount = getAmountByItemUnitMeasurement({
        shipmentSupply: bidDetail.supply,
        itemUnitMeasurement,
        shipmentItemList: bidDetail.bidItems,
        serviceType: bidDetail.serviceType,
        wareHouseCbm: bidDetail?.wareHouse?.cbm,
        totalWeight: bidDetail.totalWeight,
        totalCBM: bidDetail.totalCBM,
        unipassTotalCBM: bidDetail.management.invoiceCbm,
        freightType: bidDetail.freightType,
        directAirRton,
      });

      /**
       * LCL, AIR의 추가 포장타입인 BOX, PALLETS, CRATES, DRUMS는 물동량이 아닌 수량을 입력
       * 포장 타입이 같은 여러개의 Product가 있을 때는 0을 리턴한다.
       */
      const quantityForLCLAndAIRPackage = (() => {
        if (!isLCLOrAIRType || !isLCLAndAirPackage) return amount;

        const numberOfProductWithSamePackageType =
          checkIsSamePackageInProductList({
            shipmentItemList: bidDetail.bidItems,
            itemUnitMeasurement,
          });

        const quantityForSameLCLAndAIRPackageItem =
          getQuantityForSameLCLAndAIRPackageItem({
            shipmentItemList: bidDetail.bidItems,
            itemUnitMeasurement,
            numberOfProductWithSamePackageType,
          });

        return quantityForSameLCLAndAIRPackageItem;
      })();

      /**
       * BOX, DRUMS, PALLETS, CRATES에 한해서 유효하지 않은 값일 경우 null(수량 빈칸) 처리
       */
      newApplyFormData.amount = isLCLAndAirPackage
        ? quantityForLCLAndAIRPackage === 0
          ? null
          : quantityForLCLAndAIRPackage
        : amount;

      newApplyFormData.itemPrice = getItemPrice(
        newApplyFormData.currency,
        newApplyFormData
      );
    }

    /** 기본 수량 값(0)이 아닌 직접 입력한 수량 값이 있으면 견적단위 선택시 기본 값으로 수정하지 않음 */
    if (applyFormData.amount !== 0 && isAmountTyped) {
      newApplyFormData.amount = applyFormData.amount;

      newApplyFormData.itemPrice = getItemPrice(
        newApplyFormData.currency,
        newApplyFormData
      );
    }

    /** 견적단위를 삭제하면 수량 항목을 공백처리 */
    if (itemUnitMeasurement === null) {
      setIsAmountTyped(false);

      newApplyFormData.amount = 0;
      newApplyFormData.itemPrice = 0;
    }

    setValue(`${applyShipmentFormFeeDataType}.${formIndex}`, newApplyFormData);
  }, [
    applyFormData,
    bidDetail,
    isAmountTyped,
    setValue,
    applyShipmentFormFeeDataType,
    formIndex,
    directAirRton,
    getItemPrice,
    isLCLOrAIRType,
  ]);

  const handleFormChange = useCallback(
    ({ isAmount }: { isAmount?: boolean }) => {
      if (isAmount) {
        /** 직접 수량을 입력했는지 확인, 수량 항목이 공백 OR 0인 경우 직접 수량을 입력하지 않은 걸로 판단 */
        setIsAmountTyped(Boolean(applyFormData.amount));
      }

      setValue(
        `${applyShipmentFormFeeDataType}.${formIndex}`,
        getNewFormData()
      );
    },
    [
      setValue,
      applyShipmentFormFeeDataType,
      formIndex,
      getNewFormData,
      applyFormData.amount,
    ]
  );

  return (
    <Grid container sx={{ marginTop: "8px" }}>
      {/** 항목 */}
      <Grid item sx={{ width: "220px" }}>
        <AutoCompleteWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.item`}
          control={control}
          options={itemListForAutoCompleteOptions}
          freeSolo={true}
          value={applyFormData.item}
          required={true}
          handleEffectOnChange={(eventValue) => {
            const isVatItem = applyItems?.find((item) => {
              return item.name === eventValue;
            })?.isVAT;

            if (isVatItem) {
              setValue(
                `${applyShipmentFormFeeDataType}.${formIndex}.isVAT`,
                true
              );
              return;
            }
            setValue(
              `${applyShipmentFormFeeDataType}.${formIndex}.isVAT`,
              false
            );
          }}
        />
      </Grid>

      {/** 실비 */}
      {!isFreightApplyItem && (
        <Grid item sx={{ width: "60px" }}>
          <Controller
            control={control}
            name={`${applyShipmentFormFeeDataType}.${formIndex}.atCost`}
            render={({ field: { ref, ...field } }) => {
              return (
                <Checkbox
                  inputRef={ref}
                  checked={applyFormData.atCost}
                  onChange={(event, checked) => {
                    field.onChange(checked);
                    // 실비 선택 시 다른 값들이 처음에는 0으로 변경되어야 하기 때문에 로직을 따로 둠
                    const newApplyFormData = { ...applyFormData };
                    if (checked) {
                      newApplyFormData.unitPrice = 0;
                      newApplyFormData.amount = 0;
                      newApplyFormData.itemUnitMeasurement = "";
                      newApplyFormData.itemPrice = 0;
                      newApplyFormData.note = "실비청구";
                    }

                    newApplyFormData.itemPrice = getItemPrice(
                      newApplyFormData.currency,
                      newApplyFormData
                    );

                    setValue(
                      `${applyShipmentFormFeeDataType}.${formIndex}`,
                      newApplyFormData
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
      )}

      {/** 화폐 */}
      <Grid item sx={{ ml: isFreightApplyItem ? 2 : 0, width: 120 }}>
        <AutoCompleteWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.currency`}
          control={control}
          options={ADMIN_BID_APPLY_CURRENCY_OPTION_LIST.filter((option) => {
            return option.value !== "SGD";
          }).map((v) => {
            return v.value;
          })}
          required={applyFormData.atCost ? false : true}
          handleEffectOnChange={() => handleFormChange({})}
          value={applyFormData.currency}
        />
      </Grid>

      {/** 단가 */}
      <Grid item sx={{ ml: 2, width: 150 }}>
        <NumericFormatWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.unitPrice`}
          control={control}
          required={applyFormData.atCost ? false : true}
          handleEffectOnChange={() => handleFormChange({})}
          thousandSeparator={true}
          decimalScale={2}
          value={applyFormData.unitPrice}
          // 실비인 경우 오입력을 방지하기 위해 disabled 설정함(단가, 수량, 견적가)
          disabled={applyFormData.atCost}
        />
      </Grid>

      {/** 수량 */}
      <Grid item sx={{ ml: 2, width: 60 }}>
        <NumericFormatWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.amount`}
          control={control}
          required={applyFormData.atCost ? false : true}
          handleEffectOnChange={() => handleFormChange({ isAmount: true })}
          thousandSeparator={true}
          decimalScale={3}
          value={applyFormData.amount}
          disabled={applyFormData.atCost}
        />
      </Grid>

      {/** 견적가(외화) */}
      <Grid item sx={{ ml: 2, width: 100 }}>
        <NumericFormat
          value={(() => {
            if (applyFormData.currency === "KRW" || !applyFormData.currency) {
              // numericFormat value에서 null과 undefined을 넣을 시 인식하지 못함
              return "";
            }

            return applyFormData.unitPrice * (applyFormData.amount ?? 1);
          })()}
          customInput={TextField}
          decimalScale={2}
          variant={"standard"}
          disabled={applyFormData.atCost}
        />
      </Grid>

      {/** 견적가(KRW) */}
      <Grid item sx={{ ml: 3, width: 190 }}>
        <NumericFormatWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.itemPrice`}
          control={control}
          thousandSeparator={true}
          value={applyFormData.itemPrice}
          InputProps={
            showsPreviousItemPrice &&
            checkPriceDifference({
              itemPrice: applyFormData.itemPrice,
              previousItemPrice: applyFormData.previousItemPrice,
            })
              ? {
                  endAdornment: (
                    <Typography
                      variant="body2"
                      color={grey[400]}
                      sx={{
                        textDecoration: "line-through",
                        whiteSpace: "nowrap",
                        paddingRight: 2,
                      }}
                    >
                      {applyFormData.previousItemPrice}
                    </Typography>
                  ),
                }
              : {}
          }
          required={applyFormData.atCost ? false : true}
          disabled={applyFormData.atCost}
        />
      </Grid>

      {/** 견적단위 */}
      <Grid item sx={{ width: 170 }}>
        <AutoCompleteWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.itemUnitMeasurement`}
          control={control}
          options={
            isLCLOrAIRType
              ? ITEM_UNIT_MEASUREMENT_OPTION_LIST_FOR_LCL_AND_AIR
              : ITEM_UNIT_MEASUREMENT_OPTION_LIST
          }
          required={applyFormData.atCost ? false : true}
          handleEffectOnChange={handleItemUnitMeasurementChange}
          value={applyFormData.itemUnitMeasurement}
        />
      </Grid>

      {/** VAT 별도 */}
      <Grid item sx={{ ml: 2, width: 70 }}>
        <Controller
          control={control}
          name={`${applyShipmentFormFeeDataType}.${formIndex}.isVAT`}
          render={({ field: { ref, ...field } }) => {
            return (
              <Checkbox
                inputRef={ref}
                checked={applyFormData.isVAT}
                onChange={(event, checked) => {
                  field.onChange(checked);
                  // VAT 선택시 비과세는 false로 변경
                  const newApplyFormData = { ...applyFormData };

                  if (checked) {
                    newApplyFormData.isTaxExempt = false;
                  }

                  setValue(
                    `${applyShipmentFormFeeDataType}.${formIndex}`,
                    newApplyFormData
                  );
                }}
              />
            );
          }}
        />
      </Grid>

      {/** 비과세 */}
      <Grid item sx={{ width: 70 }}>
        <Controller
          control={control}
          name={`${applyShipmentFormFeeDataType}.${formIndex}.isTaxExempt`}
          render={({ field: { ref, ...field } }) => {
            return (
              <Checkbox
                inputRef={ref}
                checked={applyFormData.isTaxExempt}
                onChange={(event, checked) => {
                  field.onChange(checked);
                  // 비과세 선택시 VAT는 false로 변경
                  const newApplyFormData = { ...applyFormData };

                  if (checked) {
                    newApplyFormData.isVAT = false;
                  }

                  setValue(
                    `${applyShipmentFormFeeDataType}.${formIndex}`,
                    newApplyFormData
                  );
                }}
              />
            );
          }}
        />
      </Grid>

      {/** 비고 */}
      <Grid item sx={{ width: 200 }}>
        <TextFieldWithReactHookForm
          name={`${applyShipmentFormFeeDataType}.${formIndex}.note`}
          control={control}
          value={applyFormData.note}
        />
      </Grid>

      <Grid item>
        <Button
          color="error"
          onClick={handleRemoveForm}
          variant="outlined"
          size="small"
        >
          삭제
        </Button>
      </Grid>
    </Grid>
  );
}

export default ApplyForm;
